.template_page_wrapper {
    height: 798px;
}

.template_page_wrapper {
    background-position: top center !important;
    background-size: cover !important;
    height: unset;
    min-height: 100vh;
}

.template_page_wrapper {
    background-color: unset;
}

.template_page_wrapper {
    background-size: cover!important;
    /* background-repeat: no-repeat; */
    /* background-repeat: no-repeat; */
    background-position: inherit;
    /* background-position: center center; */
    background-color: var(--qrc-secondary);
    max-width: 1000%;
    padding: 0;
    padding-top: 0.1px;
    padding-bottom: 0.1px;
    overflow: auto;
    position: relative;
    height: 100vh;
    scrollbar-width: thin;
}

.template_background {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-size: cover;
    background-position: top center;
}

.template_page_inner {
    padding-top: 0;
}

.template_page_inner {
    max-width: 490px;
    margin: auto;
    position: relative;
    padding: 0px 15px 1px 15px;
    -webkit-border-top-left-radius: var(--qrc-border-radius);
    -webkit-border-top-right-radius: var(--qrc-border-radius);
    -moz-border-radius-topleft: var(--qrc-border-radius);
    -moz-border-radius-topright: var(--qrc-border-radius);
    border-top-left-radius: var(--qrc-border-radius);
    border-top-right-radius: var(--qrc-border-radius);
    padding-bottom: 90px;
    z-index: 9;
}

.qrc_profile_3 .template_profilepic img {
    height: auto;
    width: 100%;
    border-radius: 0;
}

.template_profilepic {
    position: relative;
    background-position: center;
}

.template_profilepic {
    background-position: top center;
}

.template_profilepic {
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
}

.template_profilepic img {
    width: 400px;
    height: 500px;
}

.st0 {
    fill: #ffc11a;
}

.st1 {
    fill: #061244;
}

.template_cc_card {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 7px 29px 0px #64646f33;
}

@media (max-width: 767px) {
    .qrc_profile_3 {
        margin: 0 -15px;
        border-radius: 0px !important;
        box-shadow: unset;
    }
}

.qrc_profile_3 .template_profile_inner {
    padding-top: 0;
}

.qrc_profile_3_svg {
    position: absolute;
    bottom: -1px;
}

.template_heading_text {
    text-align: center;
    padding: 15px 20px;
    margin: 15px 0;
}

.template_profile_inner_info {
    margin-top: -228px;
    padding: 0 15px;
    position: relative;
}

.qrc_profile_3 {

    background-color: #000000;
    overflow: hidden;
}

.qrc_profile_3 h2 {
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 44px;
    line-height: 46px;
    /* word-break: break-word; */
}

.qrc_profile_3 p {
    color: #fff;
    font-size: 18px;
}

.template_profile_brand_logo img {
    max-width: 160px;
    max-height: 60px;
}

.template_profile_shortcut {
    margin: 30px 0 0 0;
}

.template_profile_shortcut ul {
    margin: 0;
    padding: 0;
}

.template_profile_shortcut ul li {
    text-align: center;
    background: #333333;
    color: #fff;
    padding-top: 0px;
}

.template_profile_shortcut ul li {
    margin: 4px;
    padding: 0;
    list-style: none;
    display: inline-block;
    border-radius: 60px;
    width: 60px;
    height: 60px;
    font-size: 28px;
}

.template_profile_shortcut ul li a {
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.template_heading_text h2 {
    color: #333333;
    font-size: 28px;
    margin: 0 0 8px 0;
}

.template_heading_text p {
    font-size: 20px;
    color: #656b6c;
    margin: 0;
    word-break: break-word;
}

.template_contact {
    text-align: left;
    padding: 15px;
    margin: 15px 0;
}

.template_contact_header {
    /* display: flex; */
    text-align: center;
    align-items: center;
    border-bottom: dashed 1px #ECEDF7;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.template_contact_hdr_img {
    width: 52px;
    height: 52px;
    background-size: cover;
    border-radius: 50px;
    flex-shrink: 0;
}

.template_contact_hdr_text {
    font-size: 20px;
    margin-left: 15px;
    color: #333333;
}

.template_address_info {
    margin-bottom: 20px;
}

.template_address_info_title {
    font-size: 20px;
    color: #333333;
}

.template_contact_info_title {
    font-size: 20px;
    color: #333333;
}

.template_direction_btn {
    background-color: #333333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    margin-top: 10px;
    display: inline-block;
    text-decoration: none;
}

.template_direction_btn:hover {
    text-decoration: none;
    color: #fff;
}

.template_email_info {
    margin-bottom: 20px;
}

.template_contact_info {
    margin-bottom: 20px;
}

.template_email_info_title {
    font-size: 20px;
    color: #333333;
}

.template_contact_number,
.template_email_id,
.template_address_text {
    color: #656b6c;
    font-size: 14px;
}

.template_contact_number a,
.template_email_id a {
    color: #656b6c;
    font-size: 14px;
    text-decoration: none;
}

.template_button_wrapper {
    position: fixed;
    bottom: 25px;
    left: 15px;
    z-index: 9;
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
}

.template_button_wrapper button {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    background: #333333;
    border-radius: 52px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    border: solid 1px #ffffff60;
    color: #fff;
    font-size: 24px;
    justify-content: center;
}

.template_gallery_list li img {
    width: 100%;
}

.template_gallery_list {
    margin: 0;
    padding: 0;
    border-radius: 16px;
    overflow: hidden;
}

.template_gallery_list li {
    margin: 0 0 8px 0;
    padding: 0;
    list-style: none;
    width: 100%;
    position: relative;
    width: 100%;
    background-size: cover;
}

.template_gallery_list li:last-child {
    margin: 0 0 0px 0;
}

.cls-1 {
    fill: url(#linear-gradient);
}

.element::-webkit-scrollbar {
    width: 0 !important
}

.shareicons-template2 {
    position: fixed;
    bottom: 100px;
    left: 15px;
    z-index: 9;
}

.shareicons-template2 .facebook-share {
    padding: 10px !important;
    /* background-color: #333333 !important; */
    border-radius: 50px;
    height: 40px;
    width: 40px;
    margin: 5px;
    display: flow-root;
    color: #fff !important;

}