/* typography styling */
h2 {
    font-size: 36px;
    font-weight: 700;
}

h4 {
    font-size: 18px;
    font-weight: 500;
}

h3 {
    font-size: 24px;
    font-weight: 500;
}

h5 {
    font-size: 18px;
    font-weight: 500;
}

.pb-6 {
    padding-bottom: 90px;
}

.py-6 {
    padding: 90px 0;
}

:root {
    --primary-bg-color: #4d77fa;
}

:root {
    --primary-color: #4d77fa;
}

:root {
    --primary-bg-color-1: #120c6e;
}

:root {
    --primary-color-1: #120c6e;
}

:root {
    --secondary-bg-color: #76839c;
}

:root {
    --secondary-color: #76839c;
}

:root {
    --secondary-bg-color-1: #9fa4b3;
}

:root {
    --secondary-color-1: #9fa4b3;
}

:root {
    --folder-icon-color: #9FA4B3;
}


:root {
    --primary-black: #061244;
}

:root {
    --hightlight-color: #FF9190;
}

:root {
    --light-color: #eceef7;
}


:root {
    --color-1: #B1C0F9;
}

:root {
    --color-2: #FDC9DB;
}

:root {
    --color-3: #F0D972;
}

:root {
    --color-4: #b1f9be;
}

:root {
    --color-5: #debfff;
}


.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.secondary_color {
    color: var(--secondary-color);
    font-weight: 400;
}

.primary_color {
    color: var(--primary-black);
}


.items .ui-icon,
.ui-widget-content .ui-icon {
    margin: auto;
}

.single_color .input-group,
.gradient_color .input-group {
    width: 150px;
    margin-right: 20px;
}


.center {
    text-align: center;
    margin: auto;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-10 {
    margin-top: 10px;
}


/* profile page css */


h1.pageTitle {
    font-size: 1.5rem;
}

h1.pageTitle.seoTitle {
    font-size: 2rem;
}

.btn-link {
    color: var(--primary-color);
}

.btn-link:hover {
    text-decoration: none;
    color: var(--hightlight-color);
}


.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    min-height: 100vh;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}



a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;

}

.dropdown-menu {
    left: unset;
    right: 0;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle {
    padding: 0;
    border-radius: 36px;
    border: 0;
    outline: none;
    vertical-align: middle;
}

.user_profile_icon .dropdown-toggle img {
    width: 36px;
    border-radius: 36px;
    cursor: pointer;
}



#qr_page_prev::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 4px;
}

#qr_page_prev::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

#qr_page_prev::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--theme-color-primary);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgba(255, 255, 255, 0.55)), color-stop(0.72, rgba(255, 255, 255, 0.5)), color-stop(0.86, rgba(255, 255, 255, 0.4)));
}


svg .grid .tick line,
svg .grid .domain {
    stroke: var(--light-color);
}

.d3-axis-strong .tick text {
    fill: #333;
}

.d3-axis .tick,
.d3-axis .tick text {
    font-size: .75rem;
    fill: #333;
}


a.btn-primary:hover {
    text-decoration: none;
}


/* Rounded sliders */

.bulk_switch .slider.round,
.mfa_switch .slider.round {
    height: 24px;
    border-radius: 24px;
}

.bulk_switch .slider.round:before,
.mfa_switch .slider.round:before {
    border-radius: 50%;
}

#upload_wrapper .qr_file_upload {
    width: 58px;
    height: 58px;
}



.dropdown button {
    cursor: pointer;
}


.btn-transparent {
    background: transparent !important;
}

/* Components */


.component_card {
    margin-bottom: 1.25rem;
}

.component_card .card-header {
    padding-left: 1.25rem !important;
}

.component_card .card-header .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.component_card .card-header .card-title h6 {
    color: #666;
    font-weight: 600;
    margin: 0;
}

.component_card .card-body {
    padding-top: 0.75rem;
}

.component_card .card-header {
    padding-bottom: 0.25rem;
}

.component_card .card,
.component_card .card .card-header {
    background-color: #F4F4F4;
    border-radius: 8px !important;
}

#other_info_card_wrapper .add_more_component button.dropdown-toggle::after {
    display: none !important;
}

#other_info_card_wrapper .add_more_component .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    top: -5px !important;
    border-radius: 8px;
    padding: 10px;
}

/* sortable card */

.sortable_card_wrapper .sortable_card:first-child .component_card_move_up,
.sortable_card_wrapper .sortable_card:first-child .sortable_card:first-child .component_card_move_up {
    opacity: 0;
    cursor: unset;
}

.sortable_card_wrapper .sortable_card:first-child .sortable_card .component_card_move_up {
    opacity: 1;
    cursor: pointer;
}

.component_card .component_card_move_down {
    display: none;
}



.carousel-control-next,
.carousel-control-prev {
    width: unset !important;
}

.carousel-control-prev {
    left: -7px !important;
}

.carousel-control-next {
    right: -7px !important;
}




.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:disabled:focus,
.btn-primary:disabled:hover {
    color: #FFFFFF;
    background-color: rgba(81, 122, 250, 0.6);
    border-color: rgba(81, 122, 250, 0.6);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled:hover {
    color: var(--primary-bg-color);
    background-color: transparent;
    background-image: none;
    border-color: var(--primary-bg-color);
}


.actions a {
    padding: 0;
    flex: 1;

}



/* ---------- */

.preview_nav {
    background-color: var(--light-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.preview_nav button {
    padding: 6px 30px;
    font-size: 16px;
}

.design_nav {
    background-color: #eceef7;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.design_nav .nav-item {
    position: relative;
    margin-left: 40px;
    cursor: pointer;
}

.design_nav .nav-item::before {
    font-family: FontAwesome;
    content: "\f105";
    position: absolute;
    top: 40%;
    transform: translate(-35px, -11px);
    color: var(--secondary-color);
    font-size: 22px;
}

.design_nav .nav-item:first-child::before {
    display: none;
}

#btn_back_components_tab.hide {
    opacity: 0;
    pointer-events: none;
    display: block !important;
}


/* tab nav css */
.nav-tabs .nav-item {
    padding: 0;
}

.design_nav .nav-link {
    padding: 16px 18px 17px 6px;
    border-radius: unset !important;
    color: var(--primary-black);
    background-color: unset !important;
    margin-right: 8px;
}

.design_nav .active {
    color: #fff !important;
    border-bottom: 3px solid var(--primary-color) !important;
    background-color: unset !important;
    color: var(--primary-color) !important;
}

.design_nav .active span {
    color: #fff !important;
    background-color: var(--primary-color) !important;
}

.design_nav .nav-link span {
    display: inline-block;
    background: #06124420;
    padding: 2px;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    text-align: center;
    margin-right: 8px;
}


.short_url_slug_prepend {

    border: 1px solid #ced4da;
}

#short_url_input:read-only {
    pointer-events: none;
}

#template_name_modal .short_url_slug_prepend {
    padding: 0 1.5rem;
}

.page_template_wrapper {
    display: flex;
}

.page_template_wrapper .page_template_card {
    width: 130px;
    /*height: 165px;*/
    border-radius: 16px;
    background-color: #ddd;
    margin-right: 1rem;
    position: relative;
    cursor: pointer;

}

.page_template_wrapper .page_template_card .overlay {
    background-color: #33333394;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: absolute;
    display: none;
}

.page_template_wrapper .page_template_card.active .overlay {
    display: block;
}

.page_template_wrapper .page_template_card .overlay .card_tick {
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
}

.qr_page_component_card_actions .switchery {
    display: inline;
}

.qr_page_component_card_actions {
    display: flex;
    align-items: center;
}

.qr_page_component_card .qr_page_component_card_actions .display_on_hover {
    display: none;
}

.qr_page_component_card:hover .qr_page_component_card_actions .display_on_hover {
    display: block;
}

#btn_add_qr_component {
    box-shadow: 0 0 15px 4px #00000030;
    margin: auto;
    padding: 8px 24px;
    display: flex;
    align-items: center;
}

#btn_add_qr_component::after,
#qr_page_component_container button.dropdown-toggle::after {
    display: none;
}

.qr_page_component_card_actions a {
    margin-right: 0.5em;
    background-color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0.375rem;
}


.collapse_card .card-header {
    padding-left: 10px;
    border-bottom: solid 1px var(--light-color);
    background-color: #f0f0ff;
}

.text-left {
    text-align: left !important;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

.qr_code_page_preview .landing_page_preview_frame .qr_page_preview {
    border-radius: 20px;
    max-height: 625px;
    min-height: 625px;
    overflow: hidden;
}

.qr_code_page_preview .landing_page_preview_frame {
    position: relative;
    border-radius: 30px;
    border: solid 8px #021d48;
    height: 640px;
    max-width: 331px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 20px;
    overflow: hidden;
}

.qr_code_page_preview .landing_page_preview_frame .device-header {
    background: #021d48;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 15px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    width: 150px;
    z-index: 99;
}

#qr_page_prev iframe.landing_page_iframe {
    width: 375px;
    height: 740px;
    -webkit-transform: scale(0.852);
    -webkit-transform-origin: 0 0;
    -ms-zoom: 0.852;
    -ms-transform-origin: 0 0;
    -moz-transform: scale(0.852);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.852);
    -o-transform-origin: 0 0;
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 360px) {
  #qr_page_prev iframe.landing_page_iframe {
    width: 345px!important;
    
  }
}

.qr_code_page_preview .card-header {
    color: var(--secondary-color)
}

.qr_code_page_preview .card-header .btn-link {
    color: var(--secondary-color) !important;
    padding: 4px 0px 4px 10px;
    font-size: 20px;
}

.qr_code_page_preview.new_ui.card_fixed {
    position: sticky;
    top: -80px;

}


.switchery .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switchery .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switchery .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.switchery .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switchery input:checked+.slider {
    background-color: #4d77fa;
}

.switchery input:disabled+.slider {
    background-color: #ddd;
}

.switchery input:focus+.slider {
    box-shadow: 0 0 1px #4d77fa;
}

.switchery input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.switchery .slider.round {
    height: 20px;
    border-radius: 20px;
}

.switchery .slider.round:before {
    border-radius: 50%;
}

.qr_page_component_card .select2-selection {
    padding: 0.6rem 0px;
}

.subcomponent_sortable_wrapper .select2 {
    width: 125px !important;
}

.subcomponent_sortable_wrapper {
    background-color: #eceef7 !important;
    position: relative;
    color: var(--secondary-color);
    border: 0 !important;
    border-radius: 4px;
}

.subcomponent_sortable_wrapper .action_buttons {
    position: absolute;
    top: -20px;
    right: 20px;
}

.subcomponent_sortable_wrapper .action_buttons a {
    border: 1px solid var(--light-color);
    border-radius: 50%;
    background-color: #fff;
    width: 36px;
    height: 36px;
    padding: 0.375rem;
}

.gray_card {
    background-color: var(--light-color);
    padding: 1rem 0px;
    border-radius: 4px;
}

.floating_contact_button {
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 15px;
    right: 30px;
    z-index: 9;
    border: 0.1px solid #fff;
    color: #fff;
}

.floating_contact_button .add_contact_icon {
    color: #fff;
    background-color: #333;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
}

.contact_info_input_title {
    position: absolute;
    top: -30px;
    left: 0;
    background: #fff;
    border-radius: 4px;
}


.image_view_type_item,
.font_style_item {
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.image_view_type_card {
    position: relative;
}

a {
    text-decoration: none;
}


a {
    text-decoration: none;
}

.qrp_prev_wrapper {
    position: relative;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #4d77fa;
    outline: 0;
    box-shadow: 0px 0px 3px 3px rgba(0, 123, 255, .08);
}

@media (max-width: 767px) {}

.qr_page_component_card .img_uploaded_card img {
    width: 64px;
    height: 64px;
    background-color: #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
}

.qr_page_component_card .img_uploaded_card {
    width: 64px;
    height: 64px;
    background-color: #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-margin {
    margin-right: -1px;
}

.img_uploaded_card.multiple_img {
    position: relative;
    display: inline-block;
}

.image_view_type_card,
.font_style_card {
    padding: 0.75rem 2rem;
    border: 1px solid var(--light-color);
    border-radius: 4px;
    cursor: pointer;
    height: 62px;
    position: relative;
}

.img_uploaded_card.multiple_img .img_action {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    width: 20px;
    height: 20px;
    border: 1px solid var(--light-color);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.nav-tabs .nav-link {
    border: 0px !important;
}

.site-header {
    border-bottom: 1px solid #ccc;
    padding: .5em 1em;
    text-align: center;
}

.site-identity img {
    width: 50px;
    margin: 0;
    display: inline-block;
}

.fit-image {
    position: absolute;
    height: 100%;
}

a {
    text-decoration: none;
}

/* mobile template*/
.qrc_page_inner {
    padding-top: 0;
}

.qrc_page_inner {
    max-width: 490px;
    margin: auto;
    position: relative;
    padding: 0px 15px 1px 15px;
    -webkit-border-top-left-radius: var(--qrc-border-radius);
    -webkit-border-top-right-radius: var(--qrc-border-radius);
    -moz-border-radius-topleft: var(--qrc-border-radius);
    -moz-border-radius-topright: var(--qrc-border-radius);
    border-top-left-radius: var(--qrc-border-radius);
    border-top-right-radius: var(--qrc-border-radius);
    padding-bottom: 90px;
    z-index: 9;
}

.qrc_page_wrapper {
    height: 798px;
}

.qrc_page_wrapper {
    background-position: top center !important;
    background-size: cover !important;
    height: unset;
    min-height: 100vh;
}

.qrc_page_wrapper {
    background-color: unset;
}

.qrc_page_wrapper {
    background-size: cover !important;
    /* background-repeat: no-repeat; */
    /* background-repeat: no-repeat; */
    background-position: inherit;
    /* background-position: center center; */
    background-color: var(--qrc-secondary);
    max-width: 1000%;
    padding: 0;
    padding-top: 0.1px;
    padding-bottom: 0.1px;
    overflow: auto;
    position: relative;
    height: 100vh;
    scrollbar-width: thin;
}

.pg_background {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-size: cover;
    background-position: top center;
}

@media (max-width: 767px) {
    .qrc_profile_5 {
        border-radius: 0px !important;
        margin-top: 0;
        margin-bottom: 0px;
    }
}

.qrc_profile_5 .qrc_profile_inner {
    padding-top: 0;
    position: relative;
    height: 180px;
    color: #fff;
    border-radius: 16px;
    overflow: hidden;
    background: #1e0e52;
    display: flex;
    align-items: center;
}

.qrc_profile_5 .qrc_profilepic img {
    height: 180px;
    width: 130px;
    border-radius: 0;
    flex-shrink: 0;
}

.qrc_profile_5 .qrc_profilepic {
    height: 180px;
    width: 130px;
    border-radius: 0;
    flex-shrink: 0;
}

.qrc_profilepic {
    background-position: top center;
    margin: unset;
}

.qrc_profilepic {
    width: 194px;
    height: 194px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 200px;

}

.qrc_profile_shortcut {
    background: #ff5b12;
    border-radius: 16px;
    width: 100%;
    margin: 15px 0 0 0;
    padding: 10px 0px 10px 0px;
    text-align: center;
}

#qrc_page_qrcode_popup {
    height: 0;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
    z-index: 11;
    position: fixed;
    width: 100%;
    top: 0;
}

.qrc_profile_shortcut ul {
    margin: 0;
    padding: 0;
}

.qrc_profile_shortcut ul li a {
    /* padding: 10px; */
    color: #fff;
}

.qrc_profile_shortcut ul li {
    text-align: center;
    background: #ff5b12;
    color: #fff;
    width: 42px;
    height: 42px;
    font-size: 22px;
    padding-top: 3px;
    margin-bottom: 8px;
    border: solid 1px #fff;

}

.qrc_profile_shortcut ul li {
    margin: 5px;
    list-style: none;
    display: inline-block;
    border-radius: 60px;

}

.qr_cc_card {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 7px 29px 0px #64646f33;
}

.qrc_contact {
    text-align: left;
    padding: 15px;
    margin: 15px 0;
}

.qrc_heading_text {
    text-align: center;
    padding: 15px 20px;
    margin: 15px 0;
}

.qrc_heading_text h2 {
    color: #ff5b12;
    font-size: 28px;
    margin: 0 0 8px 0;
    font-weight: 700;
}

.qrc_heading_text p {
    font-size: 20px;
    color: #656b6c;
    margin: 0;
    word-break: break-word;
}

.qrc_contact_header {
    /* display: flex; */
    text-align: center;
    align-items: center;
    border-bottom: dashed 1px #ECEDF7;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.qrc_contact_info {
    margin-bottom: 20px;
}

.qrc_email_info {
    margin-bottom: 20px;
}

.qrc_social_text_heading,
.qrc_contact_hdr_text,
.qrc_contact_info_title,
.qrc_email_info_title,
.qrc_address_info_title,
.qrc_heading h2 {
    color: #ff5b12;

}

.qrc_social_text_heading {
    font-size: 20px;
    color: #333333;
    overflow-wrap: break-word;
}

.qrc_contact_number a,
.qrc_email_id a {
    color: var(--qrc-text-secondary);
    text-decoration: none;
}

.qrc_direction_btn {
    background-color: #ff5b12;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    margin-top: 10px;
    display: inline-block;
}

.qrc_direction_btn:hover {
    text-decoration: none;
    color: #fff;
}

.qrc_gallery_list {
    margin: 0;
    padding: 0;
    border-radius: var(--qrc-border-radius);
    overflow: hidden;
}

.qrc_gallery_list li {
    margin: 0 0 8px 0;
    padding: 0;
    list-style: none;
    width: 100%;
    position: relative;
    width: 100%;
    background-size: cover;
}

.qrc_gallery_list :last-child {
    margin: 0 !important;
}

.qrc_gallery_list li img {
    width: 100%;
}

.qrc_social_links_list {
    margin: 0;
    padding: 0;
}

.qrc_heading {
    text-align: center;
    /* border-bottom: dashed 1px #ECEDF7; */
    /* margin-bottom: 15px; */
    padding: 15px 0;
}

.qrc_social_links_list li {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 15px;
    margin: 15px 0;
}

.qrc_profile_brand_logo {
    width: 200px;
    margin: 80px auto 80px auto;
    text-align: center;
}

.qrc_profile_brand_logo img {
    max-width: 200px;
    max-height: 100px;
}

.qrc_profile_inner_info {
    padding: 0 15px;
    position: relative;
}

.qrc_contact_hdr_img {
    width: 52px;
    height: 52px;
    background-size: cover;
    border-radius: 50px;
    flex-shrink: 0;
}

.qrc_contact_hdr_text {
    font-size: 20px;
    margin-left: 15px;

}

.qrc_social_icon img {
    width: 52px;
    height: 52px;
    background-size: cover;
    border-radius: 50px;
}

.qrc_social_links_list li a {
    display: flex;
    align-items: center;
}

.qrc_social_text {
    width: calc(100% - 110px);
    margin: 0 15px;
    text-align: left;
}

.qrc_social_action {
    font-size: 20px;
    color: var(--qrc-primary);
}

.qrc_social_text_discription {
    font-size: 14px;
    color: #656b6c;
}

.qrc_social_links_list li a:hover {
    text-decoration: none;
}

.extra_button_wrapper {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 9;
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
}

.extra_button_wrapper button {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    background: #ff5b12;
    border-radius: 52px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    border: solid 1px #ffffff60;
    color: #fff;
    font-size: 24px;
    justify-content: center;
}

#qr_page_prev iframe.landing_page_iframe {
    width: 375px;
    height: 740px;
    -webkit-transform: scale(0.852);
    -webkit-transform-origin: 0 0;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 360px) {
  #qr_page_prev iframe.landing_page_iframe {
    width: 345px!important;
    
  }
}
.qr_code_page_preview .landing_page_preview_frame {
    /* border-radius: 20px; */
    max-height: 625px;
    min-height: 625px;
    overflow: hidden;
}

.shareicons {
    position: fixed;
    bottom: 100px;
    left: 15px;
    z-index: 9;
}

.shareicons .facebook-share {
    padding: 10px !important;
    /* background-color: #fb5728 !important; */
    border-radius: 50px;
    height: 40px;
    width: 40px;
    margin: 5px;
    display: flow-root;
    color: #fff !important;

}

/* background img */

.bg_img_upload_card_wrapper .img_uploaded_card {

    margin-bottom: 1rem;
}

.bg_img_upload_card_wrapper {
    max-height: 230px;
    overflow-y: scroll;
}

.thinScrollBar::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F580;
}

.thinScrollBar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #608fffcc;
}

.img_uploaded_card {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
    background-color: #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
}

.img_uploaded_card .img_action {
    content: "\e956";
    position: absolute;
    top: -5px;
    left: 0;
    background: #fff;
    color: #4d77fa;
    ;
    border-radius: 30px;
    padding: 0;
    font-size: 9px;
    width: 16px;
    height: 16px;
    font-family: 'icomoon';
    border: solid 1px #4d77fa;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}
.img_uploaded_card.remove_img img {
    margin-left: 22px;
    width: 20px;
}
/* color */
.borderbox {
    margin: 0;
    padding: 0;
}

.qr_color_panel_bg li,
.qr_color_panel_text li {
    border-radius: 50%;
}

.borderbox li.active,
.borderbox li:hover {
    border: solid 1px var(--primary-color);
}

.borderbox li {
    border: solid 1px var(--light-color);
    width: 70px;
    height: 70px;
    margin: 0 8px 8px 0;
    padding: 0;
    display: block;
    float: left;
    list-style: none;
    position: relative;
}

.qr_color_panel_wr {
    margin: 4px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    overflow: hidden;
}

.qr_color_panel_1 {
    width: 100%;
    height: 44px;
}

.qr_color_panel_2 {
    width: 100%;
    height: 26px;
}

.sp-replacer {
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    /* padding: 4px; */
    display: inline-block;
    border: solid 1px #ced4da;
    background: #eee;
    color: #333;
    width: 38px;
    border-radius: 4px;
    vertical-align: middle;
}

.sp-preview .fa-paint-brush {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #000;
    z-index: 9;
    padding-top: 12px;
    padding-left: 12px;
}

/* card style */
.boxcolorpicker {
    background-color: #e5e5e5;
    padding: 15px;
    border-radius: 10px;
}

.boxcolorpicker .comfirm {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    padding: 5px;
}

.boxcolorpicker .cancle {
    margin-top: 20px;
    background-color: #ed465a;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
}
.preview_qr_img img.active{
    box-shadow: 0px 0px 0px 2px #9090ff9f;
    border-radius: 12px;
    padding: 15px;
}
/* font style */
.font_style_card.selected{
    border-color:#4d77fa;
}

#color-overlay {
    position: absolute;
    top: 22%;
    left: 34%;
    
}


@media (max-width: 767px) {
    .mpr-15 {
        padding-right: 15px !important;
    }
}
